import React, { Component } from "react";
import MarkdownRenderer from "react-markdown-renderer";

import Layout from "../components/layout";
import { Button, Product } from "../components/styleguide";
import { createSlug } from "../../utils/constants.js";
import { Link } from "gatsby";
import HomeSlider from "../components/home-slider";

class IndexPage extends Component {
  spliceText = ({ text, numberOfLines, readMoreCharacterLimit }) => {
    let teaserText;
    let remainingWordsArray = [];

    if (text) {
      const teaserWordsArray = text.split(" ");
      while (teaserWordsArray.join(" ").length > readMoreCharacterLimit) {
        remainingWordsArray.unshift(teaserWordsArray.pop());
      }
      teaserText = teaserWordsArray.join(" ");
    }
    return `${teaserText}...`;
  };

  createSlider = (data) => {
    const slider = [];
    const randomArr = this.getRandomArray(data);
    randomArr.map((item) => slider.push(data[item]));
    return slider;
  };

  getRandomArray = (data) => {
    const numbersArray = [];
    const getNumber = () => {
      let number = this.getRandomNumber(data);
      if (numbersArray.includes(number)) {
        getNumber();
      } else {
        return numbersArray.push(number);
      }
    };
    Array(3)
      .fill(null)
      .forEach((element) => {
        getNumber();
      });

    return numbersArray;
  };

  getRandomNumber = (data) => {
    const min = Math.ceil(0);
    const max = Math.floor(data.length - 1);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  render() {
    const {
      data,
      common,
      slider,
      products,
      productsPath,
      blogPath,
      newest_post_tags,
    } = this.props.pageContext;
    const [translations] = common.translations;
    const [static_data] = common.static_data;
    const randomSlider = this.createSlider(slider);
    return (
      <Layout
        data={common}
        title={data.seoTitle}
        description={data.seoDescription}
        hrefLang={data.hrefLang}>
        <div className="main-container">
          <section className="index-slider">
            <div className="contact-info">
              <MarkdownRenderer markdown={data.title} />
              <div className="contact-links">
                <p>
                  <a href={`mailto:${static_data.main_mail}`} className="link">
                    <img
                      className="lazyload"
                      data-src={require("../images/icons/email-black.svg")}
                      alt="email"
                    />
                    <span>{static_data.main_mail}</span>
                  </a>
                </p>
                <p>
                  <a href={`tel:${static_data.main_tel}`} className="link">
                    <img
                      className="lazyload"
                      data-src={require("../images/icons/phone-black.svg")}
                      alt="phone"
                    />
                    <span>{static_data.main_tel}</span>
                  </a>
                </p>
              </div>
            </div>
            <HomeSlider data={randomSlider} translations={translations} />
          </section>
          <section>
            <header className="section-header">
              <MarkdownRenderer markdown={data.section_products_title} />
              <MarkdownRenderer markdown={data.section_products_desc} />
            </header>
            <div className="grid x4">
              {products.map((product) => (
                <Product key={product.id} data={product} home />
              ))}
              <div className="show-more-wrapper">
                <Button
                  type="link"
                  href={productsPath}
                  content={translations.show_more}
                  fill
                  size="lg"
                />
              </div>
            </div>
          </section>
          {data.newest_post && data.newest_post.length > 0 && (
            <section>
              <header className="section-header">
                <MarkdownRenderer markdown={data.section_post_title} />
              </header>
              <Link
                to={`${blogPath}/${createSlug(data.newest_post[0].title)}`}
                className="new-blog-article">
                <div className="image-holder">
                  {data.newest_post[0].home_image && (
                    <img
                      className="lazyload"
                      data-src={data.newest_post[0].home_image}
                      alt={data.newest_post[0].title.replace(
                        /(?:__|[*#])|\[(.*?)\]\(.*?\)/gm,
                        ""
                      )}
                    />
                  )}
                </div>
                <div className="text-holder">
                  <p
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: data.newest_post[0].title.replace(
                        /(?:__|[*#])|\[(.*?)\]\(.*?\)/gm,
                        ""
                      ),
                    }}
                  />
                  {data.newest_post[0].description && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: this.spliceText({
                          text: data.newest_post[0].description.replace(
                            /<[^>]*>?/gm,
                            ""
                          ),
                          numberOfLines: 4,
                          readMoreCharacterLimit: 190,
                        }),
                      }}
                    />
                  )}
                  {newest_post_tags.length > 0 && (
                    <div className="tags-list">
                      {newest_post_tags.map(({ name, id }, index) => (
                        <Link
                          key={id}
                          className="tag font-ms"
                          to={`${blogPath}/${createSlug(name)}`}>
                          #{name}
                          {index !== newest_post_tags.length - 1 ? "," : ""}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
                <div className="btn-holder">
                  <Button type="arrow-button" />
                </div>
              </Link>
            </section>
          )}
        </div>
      </Layout>
    );
  }
}

export default IndexPage;
