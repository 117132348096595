import React, { Component } from "react";
import MarkdownRenderer from "react-markdown-renderer";

import { Button } from "../components/styleguide";

class HomeSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 1
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.intervalAction();
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  intervalAction = () => {
    const { active } = this.state;

    if (active === 3) {
      return this.handleMoveSlider(1);
    }
    return this.handleMoveSlider(active + 1);
  };

  handleMoveSlider = (active, isClicked) => {
    this.setState({ active });
    isClicked && clearInterval(this.interval);
  };
  render() {
    const { active } = this.state;
    const { data, translations } = this.props;

    const getClass = index => {
      switch (index) {
        case 0:
          return ["small top", "big", "small bottom"];
        case 1:
          return ["small bottom", "small top", "big"];
        case 2:
          return ["big", "small bottom", "small top"];
        default:
          break;
      }
    };

    const imageSlider = data.map(({ image }, index) => ({
      url: image,
      className: getClass(index)
    }));
    return (
      <>
        <div className="index-slider-wrapper">
          {imageSlider.map(({ url, className }, index) => (
            <div
              key={index}
              className={`lazyload item ${className[active - 1]}`}
              style={{
                backgroundImage: `url('${url}')`
              }}
            />
          ))}
        </div>
        <div className="slider-info">
          <div
            className={`descriptions-wrapper ${active === 1 ? "left" : ""} ${
              active === 2 ? "middle" : ""
            } ${active === 3 ? "right" : ""}`}
          >
            {data.map(({ id, desc, link, title }, index) => (
              <div key={id} className={`slide-description `}>
                <div className="title">
                  <MarkdownRenderer markdown={title} />
                </div>
                <p>{desc}</p>
                <Button
                  type="link"
                  href={link}
                  content={
                    <>
                      <span>{translations.see_more}</span>
                      <img
                        style={{ maxWidth: "14px", marginLeft: "10px" }}
                        className="lazyload" data-src={require("../images/icons/arrow-right.svg")}
                        alt="arrow right"
                      />
                    </>
                  }
                  fill
                />
              </div>
            ))}
          </div>
          <div className="slider-navigation">
            {data.map((item, index) => (
              <button
                key={index}
                className={`dot ${active === index + 1 ? "active" : ""}`}
                onClick={() => this.handleMoveSlider(index + 1, true)}
              />
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default HomeSlider;
